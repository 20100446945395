const NotificationHelper = {
  _checkInsText : {
    1  : 'Parabéns pelo teu primeiro check-in! Agora faz o check-in em todos os pontos que passares e habilita-te a prémios e ofertas!',
    2  : 'Dobradinha (2 check-ins). Estás no bom caminho!',
    3  : 'Hat-trick (3 check-ins). Estás no bom caminho!',
    5  : '5 check-ins 🖐 Aos 35 check-ins já podes ganhar prémios!',
    10 : '10 check-ins! Visita mais 25 pontos de investigadores para trocares o teu primeiro prémio.',
    20 : '20 check-ins! Visita mais 15 pontos de investigadores para trocares o teu primeiro prémio.',
    35 : 'Podes trocar uma t-shirt NEI Coimbra 2023 (35 check-ins) ou mais 15 check-ins e habilitas-te a outro prémio!',
    40 : '40 check-ins! Estás a 10 check-ins de poderes trocar um prémio!',
    50 : 'Podes trocar um kit de reciclagem (50 check-ins). Visita o nosso ponto de informação ou continua, mais 15 check-ins e habilitas-te a ganhar outro prémio!',
    60 : '60 check-ins! Estás a 5 check-ins de poderes trocar um prémio!',
    65 : 'Podes trocar um prémio (65 check-ins). Visita o nosso ponto de informação e continua, mais 10 check-ins para te tornares um campeão da ciência!',
    70 : 'Estás quase a tornar-te um campeão da ciência! Faz check-in em mais 5 pontos!',
    75 : 'Parabéns, fizeste check-in em todos os pontos, a Rota da Ciência é tua! Mereces fazer parte da nossa equipa de investigação!'
  }
};

NotificationHelper.init = function (checkInCount) {
  notie.setOptions({
    alertTime: 30,
  });
};

NotificationHelper.handleCheckIn = function (checkInCount) {
  if(checkInCount in NotificationHelper._checkInsText)
    setTimeout(() => {
      notie.alert({
        type: 4,
        text: '👍 ' + NotificationHelper._checkInsText[checkInCount] + ' 🎉',
        position: 'bottom'
      });
    }, 500);
};

NotificationHelper.getCheckInText = checkInCount => {
  let index = checkInCount;

  while(index > 0) {
    if(NotificationHelper._checkInsText[index])
      return NotificationHelper._checkInsText[index];

    index--;
  }

  return '';
};
